export default {
  aboutUsTitle: 'Qui sommes-nous?',
  afiByEdgenda: 'AFI par Edgenda',
  afiByEdgendaCoachingUrl: 'https://www.afiparedgenda.com/fr',
  afiByEdgendaFastNumericLearningsUrl: 'https://www.afiparedgenda.com/fr',
  afiByEdgendaLearningExperiencesUrl: 'https://www.afiparedgenda.com/fr',
  afiByEdgendaPersonalizedProgramsUrl: 'https://www.afiparedgenda.com/fr/programs',
  afiByEdgendaRootUrl: 'https://www.afiparedgenda.com/fr',
  afiByEdgendaUrl: 'https://www.afiparedgenda.com/',
  afiU: 'Afi\xa0U.',
  apprentxB12Url: 'https://www.apprentx.rocks/fr/b12',
  apprentxRootUrl: 'https://www.apprentx.rocks/fr',
  backToCatalog: 'Retour au catalogue',
  contact: 'Contact',
  contactUrl: 'https://lp.afiexpertise.com/contactez-nous-afiu',
  cookieSettings: 'Personnaliser les témoins',
  doYouHaveQuestions: 'Vous avez des questions? Nous avons des réponses.',
  detailsAndStartCta: 'Détails et début des prochaines cohortes',
  edgendaCoachingUrl: 'https://edgenda.com/humain',
  edgendaCopyright: 'Copyright © 2025 Edgenda conseil inc.',
  edgendaLearningUrl: 'https://edgenda.com/formation',
  edgendaRootUrl: 'https://www.edgenda.com',
  edgendaStrategyUrl: 'https://edgenda.com/strategie-operations',
  edgendaTechnologyUrl: 'https://edgenda.com/technologie',
  keepPosted: 'Je veux toujours apprendre',
  magazineUrl: 'https://lp.afiexpertise.com/fr/catalogue-afi-u-2025',
  mainTitle: 'École de formation professionnelle',
  newsletterLink: '/fr/infolettre',
  newsletterSubscribe: 'Restez en contact',
  rentOfficeUrl: 'https://edgenda.formstack.com/forms/location_de_salle',
  requestQuote: 'Demandez une soumission',
  takeMeetingUrl: 'https://meetings.hubspot.com/marie-claude-langlois/rencontrer-un-conseiller',
  new: 'Nouveauté',
  yourMail: 'Votre adresse courriel',
  readMore: 'Voir plus',
  readLess: 'Voir moins',
  homeMainTitle: 'Toujours apprendre',
  prioritiesText:
    "Découvrez des expériences d'apprentissage alignées à vos priorités organisationnelles",
  homeColoredTitle: {
    firstPart: "L'École des affaires et des technologies,",
    secondPart: 'pour être prêt en tout temps',
  },
  or: 'ou',
  viewFullList: 'voir la liste complète',
  homeParagraphs: {
    first:
      'Dans un monde numérique où il faut constamment apprendre, désapprendre et réapprendre, nous travaillons avec les organisations pour faire grandir leur ressource la plus précieuse : VOUS.',
    second:
      'Plus que jamais, l’apprentissage professionnel s’inscrit dans un cycle continu et doit évoluer en temps réel à travers des activités variées et des connections humaines vivantes.',
    third:
      'Bien plus qu’une école, Afi\xa0U. est une communauté qui partage sa soif d’apprendre, ses connaissances et ses expériences concrètes pour se donner la confiance d’agir au présent.',
  },
  forIndividuals: 'Pour les individus',
  forIndividualsText:
    'Explorez différentes opportunités d’apprentissage pour augmenter votre impact.',
  businessPriorities: 'Priorités organisationnelles',
  programsMicroprograms: 'Programmes et microprogrammes',
  offShelfTrainings: 'Formations à la carte',
  afiUCircles: 'Les Cercles Afi\xa0U.',
  forOrganizations: 'Pour les organisations',
  forOrganizationsText:
    'Développez des équipes agiles pour propulser la transformation continue de votre organisation.',
  privateDelivery: 'Groupes privés',
  learningWorkshops: 'Ateliers de co-apprentissage',
  businessCoaching: 'Services de coaching',
  customPrograms: 'Programmes personnalisés',
  liveAfiUExperience: {
    beforeLogo: 'Vivez l’expérience',
    afterLogo: '',
  },
  liveAfiUText: {
    boldPart: 'Trouvez',
    regularPart: 'votre programme, microprogramme ou une formation à la carte',
  },
  newsForYou: `Une expérience qui nous distingue`,
  neverStopLearning: {
    boldPart: 'Toujours',
    regularPart: 'apprendre',
  },
  exploreActTransform: "Prêts à accueillir l'IA <br />dans votre organisation?",
  downloadExplore: 'Découvrez notre programmation',
  exploreText:
    "Des expériences d'apprentissage qui préparent vos équipes à exceller dans un avenir en évolution continu.",
  download: 'Téléchargez',
  organizationsTitle: 'Des organisations qui transforment le monde',
  organizationsText:
    'Des organisations engagées à développer les compétences de leurs équipes et prêtes à transformer le monde avec Afi\xa0U.',
  certifiedTrainings: 'Formations certifiées',
  aboutUs: 'À propos',
  afiUExperience: 'Vivre Afi\xa0U.',
  theTeam: "L'équipe",
  ourOffices: 'Nos bureaux',
  partnersAndCertifications: 'Partenaires et certifications',
  careers: 'Carrières',
  faq: 'FAQ',
  visitWebsite: 'Visitez le site',
  designDevelopment: "Expériences d'apprentissage",
  rapidELearning: 'Apprentissage numériques rapides',
  apprentxByEdgenda: 'Apprentx par Edgenda',
  edgenda: 'Edgenda',
  strategyOperations: 'Stratégie et opérations',
  coachingLeadership: 'Leadership et coaching',
  emergingTechnologies: 'Technologies émergentes',
  learning: 'Développement des compétences',
  edgendaFamily: 'La Famille Edgenda',
  needAssistance: 'Besoin d’aide ?',
  talkSales: 'Parlez à un conseiller',
  talkToAdvisor: 'Parlez à un conseiller',
  orCall: 'ou appelez au',
  privacyPolicy: 'Confidentialité',
  privacyText:
    'Centre de formation autorisé Emploi-Québec, Agrément : 0051460 | TPS : 141 582 528 - TVQ : 1019557738',
  newsletterText: 'Soyez au fait des tendances, innovations, bonnes pratiques et exclusivités',
  comingSoon: 'À venir',
  seeTheOthers: 'Voir les autres',
  readyCta: "Prêt? Passez à l'action",
  afiUSubtitle: {
    program: 'Programme Afi\xa0U.',
    microprogram: 'Microprogramme Afi\xa0U.',
    masterClass: 'Masterclass Afi\xa0U.',
  },
  afiUSubtitleSEOTitle: {
    program: 'Programme',
    microprogram: 'Microprogramme',
    masterClass: 'Masterclass',
  },
  nextCohort: 'Prochaine cohorte',
  duration: 'Durée',
  fee: 'Tarif',
  format: 'Format',
  startHereCta: 'Commencez ici',
  elevateYourSkills: 'Prêt à augmenter tes compétences?',
  beReadyTo: {
    firstPart: 'Soyez prêt à ...',
    secondPart: '... et créer des retombées directes pour votre organisation',
  },
  experienceColoredTitle: {
    firstPart: 'Vivez l’apprentissage',
    microprogramSecondPart: 'des microprogrammes Afi\xa0U.',
    programSecondPart: 'des programmes Afi\xa0U.',
    masterClassSecondPart: 'des masterclasses Afi\xa0U.',
  },
  engageText: {
    program: 'Expérience du programme',
    microprogram: 'Expérience du microprogramme',
    masterClass: 'Expérience de la masterclass',
  },
  caseStudies: 'Des cas concrets',
  interestedInThis: {
    program: 'Ce programme vous intéresse?',
    microprogram: 'Ce microprogramme vous intéresse?',
    masterClass: 'Cette masterclass vous intéresse?',
  },
  takeTheNextStep: 'Passez à l’action',
  onlineRegistration: 'Inscription en ligne',
  needPersonalizedConsultation: 'Besoin de conseils personnalisés?',
  scheduleMeeting: 'Prenez rendez-vous avec un conseiller',
  questions: 'Des questions?',
  invitedContributorsAndExperts: 'Contributeurs et experts invités',
  studyPlan: 'Plan d’études',
  downloadBrochure: 'Télécharger la brochure',
  blog: 'Blogue',
  fundingPrograms: `Programmes de subvention`,
  liveAfiUFamilleParagraph:
    "La Famille Edgenda réunit sous un même toit les spécialistes qui mettent en œuvre la transformation d'affaires. Elle active le talent humain, appuyée par la technologie, pour accélérer la réussite des organisations.",
  liveAfiUPropellSuccess:
    'Accélérons votre réussite\npar l’humain et la technologie.\nC’est notre edge.',
  themesAmbassadorsText:
    "Nos ambassadeurs proposent des expériences d'apprentissage ciblées qui propulsent vos projets et votre ambition.",
  the: 'Le',
  the_plural: 'Les',
  and: 'et',
  from: 'de',
  to: 'à',
  microprogram: 'Microprogramme',
  microprograms: 'Microprogrammes',
  program: 'Programme',
  programs: 'Programmes',
  detailsAndRegistration: 'Détails et inscription',
  stayTuned: "Restez à l'affût, grande annonce à venir!",
  offTheShelf: 'À la carte',
  mostPopularCourses: 'Formations à la carte les plus populaires',
  circlesAfiU: 'Cercles Afi\xa0U.',
  circleAfiU: 'Cercle Afi\xa0U.',
  profile: 'Profil',
  nextMeeting: 'Prochain rendez-vous',
  conferences: 'Conférences',
  businessCoach: 'Coach d’affaires',
  threeNinetyMinutesSessions: '3 sessions de 90 minutes',
  teamParagraph:
    'C’est pourquoi le succès d’Afi\xa0U. passe par la qualité de ses employés. Nous sommes conscients et respectueux de votre culture organisationnelle ainsi que de la personnalité des individus. Notre écoute et notre compréhension de vos enjeux nous permettent de trouver dans notre équipe LA personne pour répondre à VOTRE besoin.',
  teamTitle: "L'humain est notre mission",
  trainersCertifiedCoaches: 'Formateurs-coachs',
  leadership: 'Leadership',
  customerExperience: 'Expérience client',
  trainers: 'Formateurs',
  organizationsPage: {
    firstColoredTitle: {
      subTitle: 'Une approche pratique axée sur les résultats',
      firstPart: 'Des solutions d’apprentissage',
      secondPart: 'au service de la transformation',
    },
    firstParagraph:
      'Afi\xa0U. collabore avec les organisations pour bâtir des plans de développement de compétences et livrer des solutions d’apprentissage qui soutiennent les projets de transformation. Notre équipe allie une solide expérience en consultation d’affaires et en création d’expériences d’apprentissage pour mieux répondre à vos opportunités et à vos enjeux.',
    secondParagraph: {
      firstPart:
        'Faire vivre un programme, un microprogramme ou une formation Afi\xa0U. à un ou plusieurs groupes au sein de votre organisation, c’est possible.',
      secondPart:
        'Proposées sous différentes formes en virtuel, en présentiel – chez vous ou dans nos espaces d’apprentissage – ou encore en hybride, nos expériences d’apprentissage préparent vos équipes à faire face à l’évolution de l’environnement d’affaires actuel, à naviguer avec aisance dans l’ambiguïté et à réagir rapidement de manière audacieuse et innovante.',
    },
    secondColoredTitle: {
      firstPart: 'Ateliers de',
      secondPart: 'co-apprentissage',
    },
    thirdParagraph: {
      firstPart:
        'Alignez vos équipes sur des objectifs communs en exploitant la formule efficace des cercles d’Afi\xa0U. qui rallie la puissance de l’intelligence collective et de l’apprentissage par l’action.',
      secondPart:
        'Encadrés et animés par un coach d’affaires certifié, les ateliers de co-apprentissage permettent d’apprendre et d’expérimenter dans un cadre confidentiel, ouvert et sans jugement.',
      thirdPart:
        'Osez provoquer les conversations pour mettre en place un plan d’action personnalisé à votre organisation.',
    },
    quote: {
      text:
        '“\xa0C’est toujours tellement puissant de faciliter des déclics et de voir les équipes se mobiliser et se transformer à travers les ateliers de co-apprentissage.\xa0”',
      authorFullName: 'Marie-Hélène Demers',
      authorTitle: 'Vice-présidente, Coaching et leadership',
    },
    firstList: [
      'Collaboration et travail en équipe',
      'Santé psychologique',
      'Cohésion et renforcement de l’esprit d’équipe',
      'Diversité, équité et inclusion en milieu de travail',
      'Alignement et vision stratégique',
      'Responsabilisation et autonomie',
      'Communication efficace et authentique',
    ],
    fourthParagraph:
      'L’équipe d’AFI par Edgenda accompagne les individus, les équipes et organisations vers l’atteinte de plus hauts sommets. Une solution efficace dans un contexte de changement continu',
    secondList: [
      'Transformation organisationnelle',
      'Coaching des cadres et des dirigeants',
      'Culture et équipe',
      'Performance et talent',
      'Coaching des professionnels TI',
      'Diversité, équité et inclusion',
      'Supervision des coachs',
    ],
    ctaCoaching: 'Consultez nos services de coaching',
    ctaUrl: 'https://afiparedgenda.com/fr/coaching',
    thirdColoredTitle: {
      subTitle: 'Accélérez le développement de vos équipes',
      firstPart: 'Programmes',
      secondPart: 'personnalisés',
    },
    fifthParagraph: {
      firstPart:
        'L’équipe de conception d’expériences d’apprentissage d’AFI par Edgenda peut collaborer avec votre organisation pour contextualiser et bonifier les contenus d’apprentissage d’Afi\xa0U.',
      secondPart: {
        boldPart: 'Résultat?',
        regularPart: 'Une solution éprouvée pour répondre à des besoins de développement précis.',
      },
    },
    programCta: 'Voir les options de personnalisation',
  },
  contactSales: 'Contactez un conseiller',
  officePage: {
    coloredTitle: {
      firstPart: 'Laissez votre apprentissage',
      secondPart: 'vous habiter',
    },
    firstParagraph:
      'Des espaces physiques, technologiques et humains qui stimulent l’attention, la participation et la connexion humaine.',
    rentCta: 'Louez ces espaces',
    secondParagraph: {
      firstPart:
        'Inspirés de la nature, les espaces sont baignés de lumière et de verdure. Tous deux jouent un impact prouvé dans les milieux de travail en augmentant la productivité de 6%, la créativité et le mieux-être, chacun de 15%',
      secondPart:
        'Nous y allions la puissante technologie {{underlineWord1}}, basée sur la plateforme Microsoft {{underlineWord2}}, qui capte le son et l’image pour livrer une expérience à la fois naturelle et hybride. Les mouvements et les interactions sont suivis en tout temps grâce à une intelligence artificielle qui vous permet de ne rien manquer.',
    },
    helpText:
      'Chaque section peut être configurée pour accueillir des expériences sur mesure basées sur vos besoins et vos idées créatives.',
    photoCredit: 'Crédit photos : Paul Dussault',
    seeThe360Images: 'Voir les images 360°',
    rentSection: {
      title: 'Location disponible',
      text:
        "Que vous ayez besoin d’un bureau de travail, d’une salle de formation, d’un salon d'affaires ou d’un espace créatif, nos nouvelles installations ont été pensées pour stimuler l’innovation et la collaboration.",
      list: ['Aire de repos aménagée', 'WIFI gratuit', 'Café à volonté'],
    },
    sections: [
      {
        title: 'Salles de formation avec ordinateurs',
        text:
          'Utilisez nos salles avec postes informatiques pour former votre équipe et ne payez que pour les services supplémentaires dont vous avez réellement besoin.',
      },
      {
        title: 'Salles de conférence',
        text:
          'Nos aménagements sont parfaits pour la tenue de conférences et de petits événements.',
      },
      {
        title: 'Salles de réunion',
        text:
          'Utilisez notre matériel de présentation (écran, projecteur, tableau blanc, etc.) pour animer votre réunion.',
      },
      {
        title: 'Espaces individuels de travail (pods)',
        text:
          'Nos espaces individuels vous permettent de travailler en toute tranquillité. Concept adapté aux travailleurs mobiles.',
      },
      {
        title: 'Espaces pour événement',
        text:
          "Nos installations vous permettent d'organiser une réception, un cocktail ou un 5 à 7 pour accueillir vos clients dans une atmosphère conviviale et chaleureuse.",
      },
      {
        title: 'Salons',
        text:
          "Que vous organisiez une réunion hors site, un atelier créatif ou un séminaire, il suffit de laisser aller votre imagination. Nos espaces vous permettent d'agencer le mobilier et l'équipement en fonction de vos besoins",
      },
    ],
    turnkeyServiceSection: {
      title: 'Service clé en main',
      listText: 'Nous pouvons prendre en charge la coordination de vos évènements, tels que :',
      list: [
        'Formations',
        'Présentations corporatives',
        "Réunions d'affaires",
        'Colloques, conférences et séminaires',
        'Laboratoires',
        'Simulations technologiques',
        '... et bien plus encore',
      ],
      receptionText: 'Nous offrons aussi un service de réception.',
    },
    openHours: {
      title: "Heures d'ouverture : Lundi au vendredi de 8 h à 17h.",
      text: 'Disponible sur demande: De 17h à 23h ou la fin de semaine.',
    },
    contactUs: {
      title: 'Contactez-nous',
      text: 'Disponible sur demande: De 17h à 23h ou la fin de semaine.',
    },
    informationRequest: 'Demande de renseignements',
  },
  programsPage: {
    coloredTitle: {
      firstPart: "Plus d'options",
      secondPart: 'pour augmenter votre impact',
    },
    firstParagraph:
      'Que vous cherchiez à développer ou à appronfondir des compétences ou encore à acquérir des compétences pointues rapidement,',
    programsText:
      'Augmentez votre impact en expérimentant dans votre propre pratique les multiples fondamentaux des grandes tendances organisationnelles.',
    microprogramsText:
      'Développez rapidement un domaine d’expertise avec une expérience intensive d’apprentissage vous équipant de connaissances immédiatement applicables dans votre milieu de travail',

    secondTitle: 'Pourquoi choisir un programme ou un microprogramme Afi\xa0U.',
    gridContent: [
      {
        title: 'Pertinence des contenus',
        text:
          'Nos programmes et nos microprogrammes sont développés en phase avec les grandes priorités des organisations et visent à combler les écarts de connaissances et de compétences reconnus par le marché.',
      },
      {
        title: 'Technologie et plateforme de diffusion',
        text:
          'En plus d’exploiter les dernières tendances en technologies éducatives, nos programmes et microprogrammes sont propulsés par notre application B12. Cette dernière utilise la technologie et la science pour accélérer et augmenter l’impact de vos acquis sur votre quotidien professionnel.',
      },
      {
        title: 'Expertise des formateurs',
        text:
          'Experts et praticiens reconnus dans leur domaine, nos formateurs sont engagés à faire avancer les conversations et à stimuler les apprentissages.',
      },
      {
        title: 'Expérience client humaine',
        text:
          'Afi\xa0U. propose une expérience humaine et fluide soutenue par une équipe engagée. Il est donc possible de parler  à quelqu’un à chacune des étapes de votre parcours avec nous.',
      },
    ],
    helpText: "Besoin d'information ou de conseil?",
  },
  circlesPage: {
    subTitle: 'Ensemble on va plus loin',
    firstParagraph:
      'Alliez la puissance de l’intelligence collective et de l’apprentissage par l’action.',
    secondParagraph: {
      firstPart:
        'Plongez dans un espace virtuel bienveillant où vous êtes invités à partager vos enjeux et vos objectifs professionnels pour progresser collectivement.',
      secondPart:
        'Guidés par un coach d’affaires, chacun des cercles Afi\xa0U. propose un contexte spécifique et une série de trois sessions de groupe pour propulser le développement de vos compétences et accélérer la transformation de vos défis en opportunités.',
    },
    exploreText: 'Explorez nos prochains Cercles Afi\xa0U.',
    whyParticipate: 'Pourquoi participer aux Cercles Afi\xa0U. ?',
    gridContent: [
      {
        title: 'Définition d’objectifs et mise en action',
        text:
          'Grâce à la structure de ses sessions, les cercles Afi\xa0U. vous permettent d’éclaircir vous objectifs et d’identifier vos prochaines actions',
      },
      {
        title: 'Diversité des perspectives',
        text:
          'Accédez à des points de vue divers et à des approches de résolution de problèmes que vous n’auriez peut-être pas envisagés par vous-même.',
      },
      {
        title: 'Responsabilisation et autonomie',
        text:
          'Prenez en charge votre développement professionnel et profitez de la force du collectif pour demeurer engagé et soutenir votre élan.',
      },
      {
        title: 'Opportunités de réseautage',
        text:
          'Profitez des échanges structurés pour nouer des liens avec les autres participants et explorer des maillages professionnels possibles.',
      },
      {
        title: 'Retour sur l’investissement',
        text:
          'Explorez une alternative rentable au coaching individuel. L’approche nécessite des investissements financiers et de temps moindre tout en créant un impact tangible sur votre développement.',
      },
    ],
    helpText: 'Besoin d’accompagnement pour choisir votre cercle?',
  },
  nextCircle: 'Prochain cercle',
  register: 'S’inscrire',
  pricing: 'Tarifs',
  virtual: 'Virtuel',
  sevenFiftyDollars: '750 $',
  yourBusinessCoach: 'Votre coach d’affaires',
  allPrograms: 'Tous les programmes',
  allMicroprograms: 'Tous les microprogrammes',
  contactCta: 'Contactez-nous',
  quebec: 'Québec',
  quebecAddress: '1751, rue du Marais, #300 G1M 0A2',
  quebecAddressDescription: 'Vaste stationnement avec accès facile par les grands axes routiers.',
  montreal: 'Montréal',
  montrealAddress: '500, rue Saint-Jacques, #500 H2Y 1S1',
  montrealAddressDescription:
    'En plein cœur du centre-ville de Montréal, près du métro Square-Victoria-OACI.',
  tollFree: 'Sans frais',
  masterClass: 'Masterclass',
  visit: 'Visitez',
  specialOffer: {
    yourAdvisor: 'Votre conseiller',
    requestInformation: 'Demande d’informations',
    learnMore: 'En savoir plus',
    for: 'pour',
    welcomeToThePortal: {
      firstPart: 'Bienvenue sur le portail',
      secondPart: '',
    },
  },
  learningExperienceSEOTitle: 'Expériences d’apprentissage',
  featuredProgram: 'Formation vedette',
}
